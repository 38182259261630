import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import './NewYearContactSection.css'
import { customBasesUrlFunc } from "../../../utils/helperFunck";
import { useDispatch, useSelector } from "react-redux";
import { postWeeding1ResultMessage } from "../../../store/slices/Wedding1ResultMessageSlice/Wedding1ResultMessageApi";
import { wedding1ResultMessageSelector } from "../../../store/slices/Wedding1ResultMessageSlice/Wedding1ResultMessageSlice";
import { useTranslation } from "react-i18next";

const NewYearContactSection = () => {
    const dispatch = useDispatch()
    const [requiredGuest, setRequiredGuest] = useState(false);
    const resultMessage = useSelector(wedding1ResultMessageSelector)
    const { t } = useTranslation()

    const validationSchema = Yup.object().shape({
        choice: Yup.string().required(t('form.8')),
        name: Yup.string().required(t('form.8')),
        guests_count: requiredGuest ? Yup.string().required(t('form.8')) : Yup.string()
    });

    return (
        <div className="new_year_contact_section">
            <div className="container">
                <Formik
                    initialValues={{
                        choice: "",
                        name: "",
                        guests_count: "",
                    }}
                    validationSchema={validationSchema}
                    onSubmit={async (values, { resetForm }) => {
                        const params = customBasesUrlFunc()
                        values.choice === '1' ? setRequiredGuest(true) : setRequiredGuest(false); 
                        const message = {
                            token: params?.token,
                            guest_name: values.name,
                            guest_quantity: values.guests_count,
                            visit: values.choice,
                        }

                        if (params.token) {
                            await  dispatch(postWeeding1ResultMessage(message))        
                        }

                        console.log(message, 88888);

                        resetForm();

                    }}
                >
                    {({ handleChange, handleBlur, values }) => (
                        <Form className="form_contact_new_year">
                            <h2>{t('new_year_contact_section_title')}</h2>

                            <div className="new_year_radio_group_div">
                                <label>
                                    <Field
                                        type="radio"
                                        name="choice"
                                        value="1"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {t('new_year_placeholders.0')}
                                </label>
                                <label>
                                    <Field
                                        type="radio"
                                        name="choice"
                                        value="0"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {t('new_year_placeholders.1')}
                                </label>
                                <ErrorMessage
                                    name="choice"
                                    component="div"
                                    className="error-message"
                                />
                            </div>

                            <div className="new_year_name_div">
                                <Field
                                    type="text"
                                    name="name"
                                    placeholder={t('new_year_placeholders.2')}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.name}
                                />
                                <ErrorMessage
                                    name="name"
                                    component="div"
                                    className="error-message"
                                />
                            </div>

                            <div className="new_year_guests_div">
                                <Field
                                    type="text"
                                    name="guests_count"
                                    placeholder={t('new_year_placeholders.3')}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.guests_count} // Այստեղ ուղղված է
                                />
                                <ErrorMessage
                                    name="guests_count"
                                    component="div"
                                    className="error-message"
                                />
                            </div>
                            {resultMessage?.success && <span className="success">{resultMessage?.message}</span>}

                            <button className="new_year_contact_button" type="submit">{t('form.7')}</button>
                        </Form>
                    )}
                </Formik>

                <div className="new_year_contact_section_webex">
                    <h3>{t('new_year_webex_text')}</h3>
                    <a href="https://webex.am" target="_blank">Webex.am</a>
                </div>
            </div>
        </div>
    );
};

export default NewYearContactSection;
