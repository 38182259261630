import React, { useState } from 'react';
import './NewYearFeedBackSection.css'
import { useDispatch, useSelector } from 'react-redux';
import { changeNewYearFeedback, newYearAllInfo, newYearIsFeedBackValid } from '../../../store/slices/NewYearSlice/NewYearSlice';
import { useTranslation } from 'react-i18next';

function NewYearFeedBackSection() {
    const feedbackValid = useSelector(newYearIsFeedBackValid)
    const newYearInfo = useSelector(newYearAllInfo);
    const [phone, setPhone] = useState('');
    const dispatch = useDispatch()
    const {t} = useTranslation()

    const handleInputChange = (e) => {
        const value = e.target.value;
        const formattedValue = value.replace(/[^\d+]/g, '');
        setPhone(formattedValue);
        dispatch(changeNewYearFeedback(formattedValue))
    };

    console.log(phone,66);
    

    return (
        <div className='new_year_feed_back_section'>
            <div className="container">
                <div className="new_year_feed_back_section_input_div">
                    <span>{t('new_year_placeholders.13')}</span>
                    <input
                        style={{outline: feedbackValid ? '2px solid red' : 'none'}}
                        type="tel"
                        placeholder='+374 00 000 000'
                        value={newYearInfo?.feedback}
                        onChange={handleInputChange}
                    />
                </div>

                <div className="new_year_contact_section_webex">
                    <h3>{t('new_year_webex_text')}</h3>
                    <a href="https://webex.am" target="_blank">Webex.am</a>
                </div>
            </div>
        </div>
    );
}

export default NewYearFeedBackSection;
