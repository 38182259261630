import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  resources: {
    am: {
      translation: {
        headertitle: "Հարսանյաց Հրավիրատոմս",
        couple: ["Վահան", "Աննա"],
        timer: ["Հարսանիքին մնաց", "օր", "ժամ", "րոպե", "վայրկյան"],
        maintitle:
          "Սիրով հրավիրում ենք ձեզ` կիսելու մեզ հետ մեր կյանքի կարևոր և հիշարժան օրը",
        party: ["ՄԻՋՈՑԱՌՄԱՆ ՍԿԻԶԲԸ", "Աբովյան խճուղի 5", "Ինչպես հասնել", 'Այցելություն Հարսի տուն'],
        marriage: [
          "ՊՍԱԿԱԴՐՈՒԹՅԱՆ ԱՐԱՐՈՂՈՒԹՅՈՒՆ",
          "Սուրբ Մարիամ Աստվածածին եկեղեցի",
          "ք. Երևան, Արմենակյան 225",
          "Ինչպես հասնել",
        ],
        regMarriage: [
          "ԱՄՈՒՍՆՈՒԹՅԱՆ ԳՐԱՆՑՈՒՄ",
          "Florence ռեստորան",
          "ք. Երևան, Բարբյուսի 64/2",
          "Ինչպես հասնել",
        ],
        restaurant: [
          "ՀԱՐՍԱՆՅԱՑ ՀԱՆԴԻՍՈՒԹՅՈՒՆ",
          "Florence ռեստորան",
          "ք. Երևան, Բարբյուսի 64/2",
          "Ինչպես հասնել",
        ],
        formText: [
          "Խնդրում ենք հաստատել ձեր ներկայությունը միջոցառմանը",
          "Կգանք",
          " Չենք կարողանա գալ :(",
          "Անուն Ազգանուն",
          "Հյուրերի թիվ",
          "Պատասխանել",
          "Սիրով սպասում ենք ձեզ",
        ],
        autors: "Կայքը պատրաստել է",

        wedding1_buttons: ["Ստեղծել", "Տեսնել", "Ուղարկել", 'Խմբագրել'],

        leng_modal: [
          "Լեզու",
          "Ընտրեք այն լեզուն որով ցանկանում եք լինի ձեր հրավիրատոմսը",
        ],

        musicModalTitle: "Ընտրեք երաժշտություն",

        watsUpNumberTitle: "Գրեք WhatsApp-ի հեռախոսահամարը, որը պետք է ստանա հրավիրվածների տվյալները:",

        placeholderWedding1: [
          "Փեսացու",
          "Հարսնացու",
          "Միջոառման վայր",
          "Հասցե",
        ],
        birthDayHeaderTitle: ["ԾՆՆԴՅԱՆ", "ԽՆՋՈՒՅՔ"],
        birthDaytimer: [
          "Ծննդյան օրվան մնացել է",
          "օր",
          "ժամ",
          "րոպե",
          "վայրկյան",
        ],
        main_invitation: [
          "Հրավեր",
          "Հրավիրում ենք նշելու Լեօ Մեսսիյի ծննդյան 37-ամյակը։",
          "Արարողությունը տեղի կունենա «ԼԱՆՋ» ռեստորանային համալիրում։",
          "Սիրով սպասում ենք։",
          "Հանդիպման օր",
          "Ժամ",
          "Վայր",
          "«ԼԱՆՋ» ռեստորան",
          "Այգեստան թաղամաս, Գևորգ Ջահուկյան փող., 213/9 շինություն",
          "Հարգանքով`",
          " Մեսսի",
        ],
        main_restaurant: ['"ԼԱՆՋ" ռեստորան', "Ինչպես հասնել"],
        footertitle: "Խնդրում ենք հաստատել ձեր ներկայությունը",
        placholderBirthday: ["հասցե", "հեռախոսահամար", "հղում հասցեին"],
        form: [
          "Մուտքագրեք Ձեր անունը և ազգանունը",
          "Անուն  Ազգանուն",
          "Ազգանուն",
          "հաղորդագրություն",
          "Խնդրում ենք հաստատել ձեր ներկայությունը",
          "կարող ենք գալ ",
          "չենք կարող գալ",
          "ՈՒՂԱՐԿԵԼ",
          "Պարտադիր դաշտ",
          "Պարտադիր թիվ",
          "Հյուրերի քանակ",
          "Ձեր նամակը հաջողությամբ ուղարկվել է։",
        ],
        mini_footer: ["Օնլայն հրավիրատոմսը ստեղծվել է", "կողմից։"],

        birthDay: ["ՄՆԱՑԵԼ Է", "օր", "ժամ", "րոպե", "վայրկյան"],

        tiketsText: 'Սիրելի ուսուցիչներ և համադասարանցիներ, հրավիրում ենք ձեզ միասին անցկացնելու մեր ավարտական ​​երեկոն։ Տոնելու ենք Felice ռեստորանային համալիրում Սիրով սպասում ենք',

        tiketsButton: [
          'Հասցե',
          'Ստեղծել',
          'Տեսնել',
          'Ուղարկել',
          'Լավ'
        ],

        tiketsAddresTitle: 'Տվյալների ճշգրիտ լինելու համար անհրաժեշտ է google map-ից գտնել հասցեն, վերցնել հղումը և տեղադրել այն տվյալ դաշտում:',

        placeholderTikets: [
          'Անուն',
          'Ազգանուն',
          'Հեռախոսահամար',
          'Հյուրերի քանակ'
        ],

        feedBackTitle: 'Ձեր հեռախոսահամարը պահանջվում է տվյալների ճշգրտության համար',

        tiketsFormTitles: [
          'ԽՆԴՐՈՒՄ ԵՆՔ ՀԱՍՏԱՏԵՔ ՁԵՐ ՆԵՐԿԱՅՈՒԹՅՈՒՆԸ։',
          'Մուտքագրեք ձեր Անունն և Ազգանունը',
          'Խնդրում ենք հաստատել ձեր ներկայությունը',
          'կարող ենք գալ',
          'չենք կարող գալ'
        ],

        invitation: 'Հրավեր',

        homeSlider: [
          'Ավտոմատացված համակարգ - Հրավիրատոսերի ստեղծելու ավտոմատացված համակարգ',
          '5 րոպե և պատրաստ է - Ստեղծիր քո հրավիրատոմսը անմիջապես` հենց հիմա',
          'Հրավերի հաճելի տարբերակ - Կանչիր քո առիթին հարազատներիդ հաճելի տարբերակով',
          'Գեղեցիկ հրավիրատոմսերի ծառայութուն - Գեղեցիկ միջոցառումները պետք է ունենան գեղեցիկ հրավիրատոմս',
          'Գեղեցիկ հրավիրատոմսեր - Հրավիրատոմեսրի առցանց ստեղծման ամենամատչելի և գեղեցիկ  տարբերակը'
        ],
        sliderTitle: 'Գնեք հիմա',

        sliderContact: [
          'Կապ',
          'Կարող եք պատվիրել հրավիրատոմս',
          'Անուն',
          'Հեռախոսահամար',
          'Էլ. հասցե',
          'Հաղորդագրություն',
          'Հարմար ժամ ձեզ հետ կապվելու համար',
          'Ծննդյան օր',
          'Հարսանիք',
          'Ավարտական ​​երեկո',
          'Միջոցառման երեկույթ',
          'Պատվիրել'
        ],

        sliderContactValidation: [
          'Պարտադիր դաշտ',
          'Գրել  հեռախոսահամար',
          'Գրել Էլ։ հասցե'
        ],

        homePageCategoryTitle: 'Կատեգորիա',

        homePageTemplatesTitles: [
          'Առցանց հրավիրատոմսեր',
          'Ընտրեք Ձեր նախնտրած հրավիրատոմսը և ձևավորեք ինքնուրույն:'
        ],

        homePagePromoCodeSection: [
          'Ստուգել  զեղչային կոդի վավերականությունը',
          'Մուտքագրեք պրոմո կոդը, որը կարող է օգտագործվել զեղչ ստանալու համար',
          'պրոմո կոդ/կուպոն'
        ],

        homePageMiniFooter: [
          'Ստեղծվել է',
          'կողմից'
        ],

        projectButtons: [
          'Ստեղծել',
          'Խմբագրել',
          'Տեսնել',
          'Պատվիրել',
          'Ուղարկել',
          'Գնել հիմա'
        ],

        invitation_modal:[
          'Հղում հրավերի համար',
          'Պատճենված հղում',
          'պրոմո կոդը.'
        ],

        homePageCommentsName:[
          'Արամ Գորեյան',
          'Մարիաննա Աղաջանյան',
          'Վահե Առաքելյան'
        ],

        homePageCommentTexts:[
          'Տվյալ գեղեցիկ նախագծի միջոցով մեր ընկերությունը ստեղծում և կստեղծի օրիգինալ օնլայն հրավիրատոմսեր: Շատ գեղեցիկ հրավիրատոմսեր կան, որոնք հնարավոր է ստեղծել  մի քանի րոպեյի ընթացքում:',
          'Բոլորին խորհւրդ եմ տալիս օգտվել արագ հրավիրատոմս պատրաստելու այս համակարգից: Առավել շատ դուրս եկավ այն, որ WhatsApp-իս գալիս են բոլոր հյուրերի անունները և քանակը( երեկույթի ֆինանսական պլանավորման համար շատ օգրակար է)',
          'Շնորհակալ ենք գեղեցիկ օրիգինալ հրավիրատոմսերի համակարգի համար, նաև շատ արագ արձագանքելու օնլայն հրավիրատոմսը կազմելուն Ձեր աջակցության համաար: '
        ],

        homePageTarifSectionModal: 'Խնդրում ենք գրել ձեր հեռախոսահամարը մեզ հետ կապվելու համար։',

        private_modal: [
          'Մուտքագրեք պրոմո կոդը',
          'Բառ կամ տառերի և թվերի հավաքածու, որը կարող եք օգտագործել զեղչ ստանալու համար',
          'Վճարել'
        ],

        footer_bloor_title: 'Այս դաշտը խմբագրման ենթակա չէ',

        new_year_header_text: 'Սիրով հրավիրում ենք Ձեզ մասնակցելու Webex Technologies-ի Ամանորյա միջոցառմանը',

        new_year_timer_sectionTitles: [
          'Ամանորին մնաց',
          'Ամանորյա միջոցառմանը մնաց'
        ],

        new_year_timing_section_title: 'Երեկոյի ժամանակացույցը',

        new_year_timing_text:[
          'Ֆուրշետ',
          'Սաքսոֆոն',
          'Photobooth',
          'DJ',
          'Ավարտ'
        ],

        new_year_location_section_title: 'Գտնվելու վայրը',

        new_year_location_section_text: 'Սպասում ենք ձեզ Պալլադիում ռեստորանում',

        new_year_location_section_addres: 'Քոչինյան փողոց 7',
        new_year_location_btn: 'Ինչպես Հասնել',

        new_year_contact_section_title: 'Խնդրում ենք հաստատել ձեր ներկայությունը',
        new_year_placeholders:[
          'Կգամ',
          'Չեմ կարողանա գալ',
          'Անուն Ազգանուն',
          'Հյուրերի թիվ',
          'Միջոցառման Օրը',
          'Տարեթիվ',
          'Ավելացրեք ձեր լոգոյի ֆայլը',
          'Հրավերքի տեքստ',
          'Միջոցառման ծրագիրը',
          'Ժամը',
          'Միջոցառման վայրի անվանումը',
          'Միջոցառման Հասցեն',
          'Միջոցառման Հասցեի հղումը',
          'Գրեք WhatsApp-ի հեռախոսահամար'
        ],

        
        new_year_webex_text: 'Webex Technologies- ը շնորհավորում է ձեր Ամանորը',

        feedback_link_valid: 'Խնդրում ենք մուտքագրել վավեր հղում:'
      },
    },

    ru: {
      translation: {
        headertitle: "СВАДЕБНОЕ ПРИГЛАШЕНИЕ",
        couple: ["ВАГАН", "АННА"],
        timer: ["До свадьбы осталось", "День", "Час", "Минута", "Секунда"],
        maintitle:
          "Мы счастливы пригласить Вас на нашу свадьбу! Это особенный и радостный для нас день, и мы хотели бы, чтобы Вы стали частью этого праздника.",
        party: ["НАЧАЛО МЕРОПРИЯТИЯ", "Абовянское шоссе 5", "Как добраться", 'Посещение дома невесты'],
        marriage: [
          "ВЕНЧАНИЕ",
          "Церковь Святой Марии Богородицы",
          "г. Ереван, Арменакяна 225",
          "Как добраться",
        ],
        regMarriage: [
          "ЗАГС",
          "Ресторан Florence",
          "г. Ереван. Барбюса 64/2",
          "Как добраться",
        ],
        restaurant: [
          "СВАДЕБНЫЙ БАНКЕТ",
          "Ресторан Florence",
          "г. Ереван. Барбюса 64/2",
          "Как добраться",
        ],
        formText: [
          "Просим вас подтвердить свое присутствие",
          "Мы придем",
          "Не сможем прийти :(",
          "Имя Фамилия",
          "Число гостей",
          "Отправить",
          "С нетерпением ждем Вас!",
        ],
        autors: "Сайт разработал",

        wedding1_buttons: ["Создать", "Видеть", "Отправлять", 'Редактировать'],

        leng_modal: [
          "Язык",
          "Выберите язык, на котором вы хотите, чтобы ваше приглашение было",
        ],

        musicModalTitle: "Выберите музыку",

        watsUpNumberTitle: "Введите номер телефона WhatsApp, на который должна прийти информация о приглашенных.",

        placeholderWedding1: ["Жених", "Невеста", "Место проведения", "Адрес"],
        birthDayHeaderTitle: ["В Честь Дня Рождения", "Вечеринка"],
        birthDaytimer: [
          "До дня рождения осталось",
          "день",
          "час",
          "минута",
          "секунда",
        ],
        main_invitation: [
          "Приглашение",
          "Приглашаем вас отпраздновать 37-летие Лео Месси.",
          "Церемония пройдет в ресторанном комплексе «ЛАНЖ».",
          "Мы ждем с любовью.",
          "День встречи",
          "Время",
          "Место",
          "«ЛАНЖ» ресторан",
          "Айгестанский квартал, ул. Геворга Джаукяна, дом 213/9",
          "С уважением`",
          "Месси",
        ],
        main_restaurant: ['"ЛАНЖ" ресторан', "Как добраться"],
        placholderBirthday: ["адрес", "номер телефона", "ссылка на адрес"],
        footertitle: "Просим Вас подтвердить свое присутствие",
        form: [
          "Введите свое имя и фамилию",
          "Имя Фамилия",
          "Фамилия",
          "Сообщение",
          "Просим вас подтвердить свое присутствие",
          "Мы придем",
          "Не сможем прийти",
          "Отправить",
          "Обязательное поле",
          "Обязательная цифра",
          "Количество гостей",
          "Ваше письмо было успешно отправлено.",
        ],
        mini_footer: ["Онлайн-приглашение создал", ""],

        birthDay: ["Остаётся", "день", "час", "минута", "секунда"],

        tiketsText: 'Дорогие учителя и одноклассники, приглашаем вас провести наш выпускной вместе. Отмечать будем в ресторанном комплексе Феличе Ждём с любовью',

        tiketsButton: [
          'Адрес',
          'Создать',
          'Видеть',
          'Отправить',
          'ОК'
        ],

        tiketsAddresTitle: 'Чтобы данные были точными, необходимо найти адрес на карте Google, взять ссылку и вставить ее в заданное поле.',

        placeholderTikets: [
          'Имя',
          'Фамилия',
          'Номер телефона',
          'Количество гостей'
        ],

        feedBackTitle: 'Ваш номер телефона необходим для точности данных.',

        tiketsFormTitles: [
          'ПОЖАЛУЙСТА, ПОДТВЕРДИТЕ СВОЁ УЧАСТИЕ.',
          'Введите свое имя и фамилию',
          'Пожалуйста, подтвердите свое присутствие',
          'можем прийти?',
          'не сможем прийти'
        ],


        invitation: 'Приглашение',

        homeSlider: [
          'Автоматизированная система - Автоматизированная система генерации приглашений',
          '5 минут и готово – Создайте приглашение мгновенно – прямо сейчас',
          'Хороший вариант приглашения - Пригласите своих родственников на праздник красивым вариантом.',
          'Красивый сервис приглашений - Красивые мероприятия должны иметь красивые приглашения',
          'Красивые пригласительные – Самый доступный и красивый вариант создания пригласительного онлайн.'
        ],

        sliderTitle: 'Купить сейчас!',

        sliderContact: [
          'Контакт:',
          'Можно ли заказать пригласительный билет?',
          'Имя',
          'Номер телефона',
          'Электронная почта',
          'Сообщение',
          'Удобное время для связи с вами',
          'День рождения',
          'Свадьба',
          'Выпускной вечер',
          'Вечеринка',
          'Заказать'
        ],

        sliderContactValidation: [
          'Обязательное поле',
          'Неверный номер телефона',
          'Неверный адрес электронной почты'
        ],

        homePageCategoryTitle: 'КАТЕГОРИЯ',

        homePageTemplatesTitles: [
          'Онлайн-приглашения',
          'Выберите пригласительный билет по вашему выбору и создайте его самостоятельно.'
        ],

        homePagePromoCodeSection: [
          'Как проверить, действителен ли промокод?',
          'Введите слово или набор букв и цифр, которые можно использовать для получения скидки',
          'промокод/купон'
        ],

        homePageMiniFooter: [
          'Создано',
          ''
        ],

        projectButtons: [
          'Создать',
          'Редактировать',
          'Видеть',
          'Заказать',
          'Отправлять',
          'Купить',
        ],

        invitation_modal:[
          'Ссылка для приглашения',
          'Копировать ссылку',
          'промокод:'
        ],

        homePageCommentsName:[
          'Арам Гореян',
          'Марианна Агаджанян',
          'Ваге Аракелян'
        ],

        homePageCommentTexts:[
          'Наша  компания использует следующий красивый проект. Множество классных приглашений мы создали за считанные минуты.',
          'Советую всем  воспользоватся системой быстрого изготовления онлайн пригласительных. Больше всего поразило то, что имена и количество всех гостей приходят мне в WhatsApp (очень полезно для финансового планирования мироприятий).',
          'Благодарим вас за прекрасную оригинальную систему приглашений, а также за вашу поддержку в быстром создании онлайн-приглашения.'
        ],


        homePageTarifSectionModal: 'Пожалуйста, напишите свой номер телефона, чтобы связаться с нами.',

        private_modal: [
          'Введите промокод',
          'Слово или комбинация букв и цифр, которые можно использовать для получения скидки.',
          'Платить'
        ],

        footer_bloor_title: 'Это поле недоступно для редактирования',

        new_year_header_text: 'Сердечно приглашаем вас принять участие в новогоднем мероприятии Webex Technologies',

        new_year_timer_sectionTitles: [
          'Уехал на Новый год',
          'Останавливались на Новогоднем мероприятии'
        ],

        new_year_timing_section_title: 'Вечернее расписание',

        new_year_timing_text:[
          'Фуршет',
          'Саксофон',
          'Photobooth',
          'DJ',
          'Окончание'
        ],

        new_year_location_section_title: 'Расположение',

        new_year_location_section_text: 'Ждем вас в ресторане Палладиум',

        new_year_location_section_addres: 'улица Кочиняна 7',
        new_year_location_btn: 'Как добраться',

        new_year_contact_section_title: 'Пожалуйста, подтвердите свое присутствие',
        new_year_placeholders:[
          
          'Я приеду',
          'Я не смогу прийти',
          'Имя Фамилия',
          'Количество гостей',
          'День мероприятия',
          'Год',
          'Добавьте файл с вашим логотипом',
          'Текст приглашения',
          'Программа мероприятия',
          'Время',
          'Название места проведения мероприятия',
          'Адрес мероприятия',
          'Ссылка на адрес мероприятия',
          'Введите номер телефона WhatsApp'
        ],

        
        new_year_webex_text: 'Webex Technologies поздравляет вас с Новым годом!',

        feedback_link_valid: 'Введите корректную ссылку.'
      },
    },

    en: {
      translation: {
        headertitle: "WEDDING INVITATION",
        couple: ["Vahan", "Anna"],
        timer: [
          "Left until the wedding",
          "Days",
          "Hours",
          "Minutes",
          "Seconds",
        ],
        maintitle:
          "We are happy to invite you to our wedding! This is a special and joyful day for us, and we would like you to become part of this holiday.",
        party: ["START OF THE EVENT", "Abovyan Highway 5", "How to get there", "A Visit to the Bride's House"],
        marriage: [
          "WEDDING",
          "Church of Surb Mariam Astvacacin",
          "c. Yerevan, Armenakyan 225",
          "How to get there",
        ],
        regMarriage: [
          "MARRIAGE REGISTRY",
          "Restaurant Florence",
          "c. Yerevan. Barbyussi 64/2",
          "How to get there",
        ],
        restaurant: [
          "WEDDING BANQUET",
          "Restaurant Florence",
          "c. Yerevan. Barbyussi 64/2",
          "How to get there",
        ],
        formText: [
          "We ask you to confirm your presence",
          "We will come",
          "We can't come :(",
          "Full name",
          "Number of guests",
          "Send",
          "We look forward to see you!",
        ],
        autors: "Website developed",

        wedding1_buttons: ["Create", "View", "Send", 'Edit'],

        leng_modal: [
          "Language",
          "Choose the language you want your invitation to be in",
        ],

        musicModalTitle: "Select music",

        watsUpNumberTitle: "Enter the WhatsApp phone number that should receive invitee information.",

        placeholderWedding1: ["Groom", "Bride", "Location", "Address"],
        birthDayHeaderTitle: ["Birthday", "Party"],
        birthDaytimer: [
          "left for the birthday",
          "day",
          "time",
          "minute",
          "second",
        ],
        main_invitation: [
          "Invitation",
          "We invite you to celebrate Leo Messi’s 37th anniversary!",
          "The ceremony will take place in «LANJ» restaurant complex.",
          "We are waiting with love.",
          "Meeting day",
          "Hour",
          "Place",
          "«LANJ» restaurant",
          "Aygestan district, Gevorg Jahukyan str., building 213/9",
          "With respect`",
          "Messi's",
        ],
        main_restaurant: ['"LANJ" restaurant', "How to get there"],
        placholderBirthday: ["address", "phone number", "address_link"],
        footertitle: "Please confirm your presence.",
        form: [
          "Enter your Name and Surname",
          "Name Surname",
          "Surname",
          "Message",
          "Please confirm your presence",
          "We will come",
          "We can't come",
          "SEND",
          "Required field",
          "Required number",
          "Number of guests",
          "Your email has been sent successfully.",
        ],
        mini_footer: ["Online invitation created by", ""],

        birthDay: ["Remains", "day", "hour", "minute", "second"],

        tiketsText: 'Dear teachers and classmates, we invite you to spend our prom together. We will celebrate at the Felice restaurant complex We are waiting with love',

        tiketsButton: [
          'Address',
          'Create',
          'View',
          'Send',
          'OK'
        ],

        tiketsAddresTitle: 'In order for the data to be accurate, it is necessary to find the address from google map, take the link and insert it in the given field',

        placeholderTikets: [
          'Name',
          'Surname',
          'Phone number',
          'Number of guests'
        ],

        feedBackTitle:'Your phone number is required for data accuracy.',

        tiketsFormTitles: [
          'PLEASE CONFIRM YOUR PRESENCE.',
          'Enter your First and Last Name',
          'Please confirm your presence',
          'Can we come',
          "we can't come"
        ],

        invitation: 'Invitation',

        homeSlider: [
          'Automated System - Automated system for generating invites',
          "5 minutes and it's ready - Create your invitation instantly - right now",
          'Nice option of invitation - Invite your relatives to your occasion with a nice option',
          'Beautiful invitation service - Beautiful events must have beautiful invitations',
          'Beautiful invitation cards - The most affordable and beautiful option for creating an invitation card online'
        ],
        sliderTitle: 'Shop Now',

        sliderContact: [
          'Contact',
          'Can you order an invitation card?',
          'Name',
          'Phone number:',
          'Email address:',
          'Message',
          'A convenient time to contact you',
          'Birthday',
          'Wedding',
          'Graduation evening',
          'Event party',
          'To order'
        ],

        sliderContactValidation: [
          'Required field',
          'Invalid phone number',
          'Invalid email'
        ],

        homePageCategoryTitle: 'CATEGORY',

        homePageTemplatesTitles: [
          'Online invitation cards',
          'Choose the invitation card of your choice and design it yourself.'
        ],

        homePagePromoCodeSection: [
          'Check the discount code is validity',
          'Enter a word or a set of letters and numbers that can be used to get a discount',
          'promocode/cupon'
        ],

        homePageMiniFooter: [
          'Created by',
          ''
        ],

        projectButtons: [
          'Create',
          'Edit',
          'To see',
          'To order',
          'Send',
          'Buy now',
        ],

        invitation_modal:[
          'Link for invitation',
          'Copied link',
          'promo code is: '
        ],

        homePageCommentsName:[
          'Aram Goreyan',
          'Marianna Aghajanyan',
          'Vahe Arakelyan'
        ],

        homePageCommentTexts:[
          'We use the following beautiful project with great pleasure. There are many  really beautiful invitations that we created in minutes.',
          'I advise everyone to get a quick invitation card by this invitation creating online system. What I liked the most was that the names and quantity of all the guests come to my WhatsApp (very useful for financial planning of the event).',
          'Thank you for the beautiful original invitation system, also for your support in creating the online invitation quickly.'
        ],

        homePageTarifSectionModal: 'Please write your phone number to contact us.',

        private_modal: [
          'Enter promo code',
          'A word or set of letters and numbers that you can use to get a discount',
          'Pay'
        ],

        footer_bloor_title: 'This field is not editable.',

        new_year_header_text: "We cordially invite you to participate in the Webex Technologies New Year's event.",

        new_year_timer_sectionTitles: [
          "It's New Year's Eve.",
          "Stayed at the New Year's event"
        ],

        new_year_timing_section_title: 'Timing of our evening',

        new_year_timing_text:[
          'Furshet',
          'Saxophone',
          'Photobooth',
          'DJ',
          'End'
        ],

        new_year_location_section_title: 'Location',

        new_year_location_section_text: 'We are waiting for you at the Palladium restaurant.',

        new_year_location_section_addres: 'Kochinyan Street 7',
        new_year_location_btn: 'How to Get There',

        new_year_contact_section_title: 'Please confirm your presence.',
        new_year_placeholders:[
          'I will come.',
          "I won't be able to come.",
          'Name Surname',
          'Number of guests',
          'Event Day',
          'Year',
          'Add your logo file.',
          'Invitation text',
          'Event program',
          'Time',
          'Event venue name',
          'Event Address',
          'Event Address Link',
          'Write your WhatsApp phone number'
        ],

        
        new_year_webex_text: 'Webex Technologies wishes you a Happy New Year!',

        feedback_link_valid: 'Please enter a valid link.'
        
      },
    },
  },
  lng: localStorage.getItem("lang") ? localStorage.getItem("lang") : "am", // Default language
  fallbackLng: localStorage.getItem("lang"), // Fallback language
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
