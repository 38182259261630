import React from 'react'
import './NewYearFixedButton.css'
import { selectProjectData } from '../../../store/slices/GetProjectSlice/GetProjectSlice';
import { useDispatch, useSelector } from 'react-redux';
import { changeNewYearIsDateValid, changeNewYearIsFeedBackValid, changeNewYearIsInvitationNameValid, changeNewYearIsLogoValid, changeNewYearIsSection_1_textValid, changeNewYearIssection_3_AddresValid, changeNewYearIssection_3_textValid, newYearAllInfo, newYearChangeEdit, newYearChangeView, newYearIsChange } from '../../../store/slices/NewYearSlice/NewYearSlice';
import { useTranslation } from 'react-i18next';
import { eyeIconNewYear } from '../../../iconsFolder/icons';
import { openModalPrivate } from '../../../store/slices/ModalPrivate/ModalPrivateSlice';

function NewYearFixedButton() {
  const respProjectData = useSelector(selectProjectData);
  const isChange = useSelector(newYearIsChange)
  const newYearInfo =useSelector(newYearAllInfo)
  const {t} = useTranslation()

  const dispatch = useDispatch()

  const editTemplateBtn = () => {
    // if (count === 1) {
    //   dispatch(birthDayChangeLAngModal())
    // }
    dispatch(newYearChangeEdit())
  }

  const viewTemplateBtn = () => {
    // setCount(count + 1);
    dispatch(newYearChangeView());
  };

  function checkNonEmptyValues(obj) {
    return Object.values(obj).some(value => {
        return (Array.isArray(value) && value.length > 0) || (typeof value === 'string' && value.trim() !== '');
    });
}

const handleSendInfo = (e) => {
  const { date, feedback, invitation_name, section_1_images, section_1_text, section_3_text, section_3_addres } = newYearInfo;


  const isDateValid = !!date;
  const isFeedbackValid = !!feedback;
  const isInvitationNameValid = !!invitation_name;
  const isTextValid = !!section_1_text
  const isSection_3_textValid = !!section_3_text
  const isSection_3_addresValid = !!section_3_addres
  const isLogoValid = section_1_images && section_1_images.length > 0

  console.log(isLogoValid,'kkkkkk');
  
  

  dispatch(changeNewYearIsDateValid(!isDateValid));
  dispatch(changeNewYearIsFeedBackValid(!isFeedbackValid));
  dispatch(changeNewYearIsInvitationNameValid(!isInvitationNameValid));
  dispatch(changeNewYearIsSection_1_textValid(!isTextValid))
  dispatch(changeNewYearIssection_3_textValid(!isSection_3_textValid))
  dispatch(changeNewYearIssection_3_AddresValid(!isSection_3_addresValid))
  dispatch(changeNewYearIsLogoValid(!isLogoValid))
  dispatch(changeNewYearIsLogoValid(!isLogoValid));
  


  if (isDateValid && isFeedbackValid && isInvitationNameValid && isTextValid && isSection_3_textValid && isSection_3_addresValid) {
    dispatch(openModalPrivate('new_year'));
  }
};

  return (
    <>
        {(!respProjectData?.success) && isChange === 'view' && <button className='fixed_button_newYear' onClick={editTemplateBtn}>{checkNonEmptyValues(newYearInfo) ? t('projectButtons.1') : t('projectButtons.0')}</button>}
        {isChange === 'edit' && <button className='fixed_button_newYear_view' onClick={viewTemplateBtn}>{eyeIconNewYear}</button>}
        {(!respProjectData?.success) &&  <button  className='fixed_button_newYear_send' onClick={handleSendInfo}>{t('projectButtons.4')}</button>}
    </>
  )
}

export default NewYearFixedButton