import React, { useState } from 'react';
import './NewYearLocationSection.css';
import { new_year_location_icon } from '../../../iconsFolder/icons';
import { 
    changeNewYearSection_3_addres, 
    changeNewYearSection_3_addresLink, 
    changeNewYearSection_3_text, 
    newYareIsEventAddresValid, 
    newYareIsEventVenueNameValid, 
    newYearAllInfo, 
    newYearIsChange 
} from '../../../store/slices/NewYearSlice/NewYearSlice';
import { useDispatch, useSelector } from 'react-redux';
import { selectProjectData } from '../../../store/slices/GetProjectSlice/GetProjectSlice';
import { useTranslation } from 'react-i18next';
import TutorialVideo from '../../TutorialVideo/TutorialVideo';
import { customBasesUrlFunc } from '../../../utils/helperFunck';

function NewYearLocationSection({ index, section }) {
    const isChange = useSelector(newYearIsChange);
    const newYearInfo = useSelector(newYearAllInfo);
    const respProject = useSelector(selectProjectData);
    const eventVenueNameValid = useSelector(newYareIsEventVenueNameValid)
    const eventAddresValid = useSelector(newYareIsEventAddresValid)
    const [error, setError] = useState("");
    const dispatch = useDispatch();
    const { t } = useTranslation();

    console.log(eventAddresValid,'---');
    console.log(eventVenueNameValid,'---88');
    
    

  const validateUrl = (value) => {
    const urlRegex = /^(https?:\/\/)?([\w\d-]+\.){1,}[\w\d-]{2,}(\/[\w\d-._~:/?#[\]@!$&'()*+,;=%]*)?$/i;
    return urlRegex.test(value);
  };

  const handleChange = (e) => {
    const value = e.target.value;
    if (validateUrl(value)) {
      setError(""); // Убираем ошибку, если URL валиден
    } else {
      setError(t('feedback_link_valid')); // Устанавливаем ошибку, если URL не валиден
    }
    dispatch(changeNewYearSection_3_addresLink(value)); // Диспатчим значение в Redux
  };

  function checkNonEmptyValues(obj) {
    return Object.values(obj).some(value => {
        return (Array.isArray(value) && value.length > 0) || (typeof value === 'string' && value.trim() !== '');
    });
}

 const params = customBasesUrlFunc()
   
    
    return (
        <div className="new_year_location_section">
            <div className="container">
                <h2 className="new_year_location_section_title">{t('new_year_location_section_title')}</h2>
                {isChange === 'view' ? (
                    <div className="new_year_location_section_content">
                        <h3>{respProject?.data?.date || newYearInfo?.date || '22.12.24'}</h3>
                        <p>{section?.text || newYearInfo?.section_3_text || t('new_year_location_section_text')}</p>
                        <div className="new_year_location_section_content_addres">
                            <span>{new_year_location_icon}</span>
                            <span>{section?.address || newYearInfo?.section_3_addres || t('new_year_location_section_addres')}</span>
                        </div>


                        {checkNonEmptyValues(newYearInfo) === false && (!params?.token) && (!newYearInfo?.section_3_addresLink) &&  <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://yandex.by/maps/10262/yerevan/?ll=44.592467%2C40.252896&mode=routes&rtext=40.157360%2C44.510709~40.253241%2C44.591325&rtt=auto&ruri=~ymapsbm1%3A%2F%2Forg%3Foid%3D40482897162&z=18"
                                className="new_year_location_section_content_button"
                            >
                                {t('new_year_location_btn')}
                            </a>}
                        {section?.address_link ? (
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={section?.address_link}
                                className="new_year_location_section_content_button"
                            >
                                {t('new_year_location_btn')}
                            </a>
                        ) : newYearInfo?.section_3_addresLink ? (
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={newYearInfo?.section_3_addresLink}
                                className="new_year_location_section_content_button"
                            >
                                {t('new_year_location_btn')}
                            </a>
                        ) : null}
                    </div>
                ) : (
                    <div className="new_year_location_section_edit_content">
                        <div className="new_year_location_section_edit_content_input_div">
                            <span>{t('new_year_placeholders.10')}</span>
                            <input
                                type="text"
                                value={newYearInfo?.section_3_text}
                                onChange={(e) => dispatch(changeNewYearSection_3_text(e.target.value))}
                                style={{outline: eventVenueNameValid ? '2px solid red' : 'none'}}
                            />
                        </div>

                        <div className="new_year_location_section_edit_content_input_div">
                            <span>{t('new_year_placeholders.11')}</span>
                            <input
                                type="text"
                                value={newYearInfo?.section_3_addres}
                                onChange={(e) => dispatch(changeNewYearSection_3_addres(e.target.value))}
                                style={{outline: eventAddresValid ? '2px solid red' : 'none'}}
                            />
                        </div>

                        <div className="new_year_location_section_edit_content_input_div">
                            <span>{t('new_year_placeholders.12')}</span>
                            <input
                                type="text"
                                value={newYearInfo?.section_3_addresLink}
                                onChange={handleChange}
                            />
                            {error && <p className="error">{error}</p>}
                            <TutorialVideo />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default NewYearLocationSection;
