import { createSlice } from "@reduxjs/toolkit";
import { act } from "react";
import { change } from "../ChangeInfoSlice/ChangeInfoSlice";

const newYearSlice = createSlice({
    name: 'newYear',
    initialState: {
        isChange: 'view',
        linkModal: false,
        lengModal: false,
        isDateValid: false,
        isFeedBackValid: false,
        isInvitationNameValid: false,
        isLogoValid: false,
        isTextValid: false,
        isEventVenueNameValid: false,
        isEventAddresValid: false,
        allInfo: {
            date: '',
            feedback: '',
            invitation_name: '',

            section_1_text: '',
            section_1_images: [],

            
            section_2_timingArr: [],

            section_3_text: '',
            section_3_addres: '',   
            section_3_addresLink: '',
        }
    },
    reducers: {
       
        newYearChangeEdit(state) {
            state.isChange = 'edit';
        },
        newYearChangeView(state) {
            state.isChange = 'view';
        },
        // ===================================

        changeNewYearFeedback(state, action) {
            state.allInfo.feedback = action.payload;
            
        },
        
        changeNewYearInvitationName(state, action) {
            state.allInfo.invitation_name = action.payload;    
        },

        changeNewYearDate(state, action) {
            state.allInfo.date = action.payload;
        },

        changeNewYearSection1Images(state, action) {
            state.allInfo.section_1_images = [action.payload];
            
        },

        changeNewYearSection_1_text(state, action) {
            state.allInfo.section_1_text = action.payload;
        },

        changeSection_2_timingArr(state, action) {
            state.allInfo.section_2_timingArr = action.payload;
            console.log(action.payload,666888);
            
        },

        changeNewYearSection_3_text(state, action) {
            state.allInfo.section_3_text = action.payload;
        },

        changeNewYearSection_3_addres(state, action) {
            state.allInfo.section_3_addres = action.payload;
        },

        changeNewYearSection_3_addresLink(state, action) {
            state.allInfo.section_3_addresLink = action.payload;
        },

        changeNewYearIsDateValid(state, action) {
            state.isDateValid = action.payload;
        },

        changeNewYearIsFeedBackValid(state, action) {
            state.isFeedBackValid = action.payload;
        },

        changeNewYearIsInvitationNameValid(state, action) {
            state.isInvitationNameValid = action.payload;
        },

        changeNewYearIsSection_1_textValid: (state, action) => {
            state.isTextValid = action.payload
        },

        changeNewYearIssection_3_textValid:(state, action)=> {
            state.isEventVenueNameValid = action.payload
        },

        changeNewYearIssection_3_AddresValid:(state, action)=> {
            state.isEventAddresValid = action.payload
        },

        changeNewYearIsLogoValid:(state, action)=> {
            state.isLogoValid = action.payload
        }

    },
});

export const newYearIsChange = (state) => state.newYear.isChange;
export const newYearAllInfo = (state) => state.newYear.allInfo;
export const newYearIsDateValid = (state) => state.newYear.isDateValid;
export const newYearIsFeedBackValid = (state) => state.newYear.isFeedBackValid;
export const newYearIsInvitationNameValid = (state) => state.newYear.isInvitationNameValid;
export const newYareIsTextValid = (state) => state.newYear.isTextValid;
export const newYareIsEventVenueNameValid = (state) => state.newYear.isEventVenueNameValid;
export const newYareIsEventAddresValid = (state) => state.newYear.isEventAddresValid;
export const newYearIsLogoValid = (state) => state.newYear.isLogoValid;

export const {
newYearChangeEdit,
newYearChangeView,
changeNewYearInvitationName,
changeNewYearDate,
changeNewYearSection1Images,
changeNewYearSection_1_text,
changeSection_2_timingArr,
changeNewYearSection_3_text,
changeNewYearSection_3_addres,
changeNewYearSection_3_addresLink,
changeNewYearFeedback,
changeNewYearIsDateValid,
changeNewYearIsFeedBackValid,
changeNewYearIsInvitationNameValid,
changeNewYearIssection_3_AddresValid,
changeNewYearIssection_3_textValid,
changeNewYearIsSection_1_textValid,
changeNewYearIsLogoValid
} = newYearSlice.actions;
export default newYearSlice;
